import React, { useState, useEffect } from 'react';
import { Avatar, notification } from 'antd';
import { generatePath, Link, useHistory, NavLink, useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import { InfoWraper, NavAuth, UserDropDwon } from './auth-info-style';
import logo from '../../../static/img/flag/store1.png';
import Message from './message';
import Notification from './notification';
// import Settings from './settings';
import Support from './support';
import { Popover } from '../../popup/popup';
import { Dropdown } from '../../dropdown/dropdown';
import { logOut } from '../../../redux/authentication/actionCreator';
import Heading from '../../heading/heading';
import Axios from 'axios';
import { headers } from '../../../helpers/variables';
//import Cookies from 'universal-cookie';
import Cookies from 'js-cookie';
//const cookies = new Cookies();
const domainpath = process.env.REACT_APP_DOMAIN_ENDPOINT;
const { encrypttheid, decodetheid } = require('../../../helpers/encode-decode');
const { encrypt, decrypt } = require('../../../helpers/encryption-decryption');
var allStores = [];
var userRole = {};
const AuthInfo = () => {
  const history = useHistory();
  const [changeStoreDependencyVariable, setchangeStoreDependencyVariable] = useState(true);
  const dispatch = useDispatch();
  const [state, setState] = useState({
    flag: 'english',
  });
  const { path } = useRouteMatch();
  const [storeUser, setStoreUser] = useState({
    user: [],
  });

  const [storeInfo, setStoreInfo] = useState();
  const { flag } = state;
  const { store } = state;
  const [userDetail, setUserDetail] = useState();
  var currentStoreName = [];
  var adminaccess_token = Cookies.get('adminAccess_token');
  useEffect(() => {
    const enc_user_detail = Cookies.get('UserDetail');
    const response = decrypt(enc_user_detail);
    const userdata = response?.sessdata?.user?.[0];
    setUserDetail(userdata);
  }, [changeStoreDependencyVariable]);

  const SignOut = e => {
    e.preventDefault();
    dispatch(logOut());
    history.push('/');
  };

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        {/* {storeUser?.user?.map((item, i) => { */}
        {/* console.log(item); */}
        <figure
          // key={i}
          className="user-dropdwon__info"
        >
          {/* <img src={require('../../../static/img/avatar/chat-auth.png')} alt="" /> */}
          <figcaption>
            <Heading as="h5">
              {userDetail?.first_Name} {userDetail?.last_Name}
            </Heading>
            {/* <p>UI Expert</p> */}
          </figcaption>
        </figure>
        <ul className="user-dropdwon__links">
          <li>
            <a href="https://sentseen.com/account-dashboard/">
              <FeatherIcon icon="user" size={14} />
              Profile
            </a>
          </li>
          {/* {userDetail?.user_role == 'client' ? ( */}
          <li>
            <a href="https://sentseen.com/account-login/?redirect_to=https%3A%2F%2Fsentseen.com%2Faccount-billing-information-page%2F">
              <FeatherIcon icon="dollar-sign" size={14} />
              Billing
            </a>
          </li>
          {/* ) : (
            ''
          )} */}
        </ul>
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <FeatherIcon icon="log-out" /> Sign Out
        </Link>
      </div>
    </UserDropDwon>
  );

  const onFlagChangeHandle = value => {
    setState({
      ...state,
      flag: value,
    });
  };
  const LogoutSecret = () => {
    Cookies.set('UserDetail', Cookies.get('adminUserDetail'));
    Cookies.set('access_token', Cookies.get('adminAccess_token'));

    Cookies.remove('adminUserDetail');
    Cookies.remove('adminAccess_token');
    history.push('./admin');
    window.location.reload();
  };
  return (
    <InfoWraper>
      <div className="mainNav_author">
        {adminaccess_token != undefined ? (
          <div onClick={() => LogoutSecret()}>
            <FeatherIcon className="facebookicon" style={{ marginTop: '4px', cursor: 'pointer' }} icon="log-out" />
            <div id="onhoverfb">Back to Dashboard</div>
          </div>
        ) : (
          ''
        )}
        <div className="nav-author">
          <Popover placement="bottomRight" content={userContent} action="click">
            <Link to="#" className="head-example">
              <Avatar src="https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png" />
            </Link>
          </Popover>
        </div>
      </div>
    </InfoWraper>
  );
};

export default AuthInfo;
